import React, { useState, useEffect } from "react";
// import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import { UserForm, PsdPreview } from "./components";
// import { Button } from "antd";

const CreateUserInformation = () => {
  const navigate = useNavigate();

  // Handle Back
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      {/* <Button type="primary" icon={<ArrowLeftOutlined />} onClick={handleBack}>
        Back
      </Button> */}
      <UserForm />
    </>
  );
};

export default CreateUserInformation;
