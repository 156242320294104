import React, { useState, useRef, useEffect } from "react";
import { Button, Col, Modal, Row, Typography } from "antd";
import { CameraOutlined, UploadOutlined } from "@ant-design/icons";
import base64ToFile from "../../../../utils/base64ToFile";
import UserApi from "../../../../services/apis/User.Api";

import { WebCam } from "..";
import DealershipApi from "../../../../services/apis/Dealership.Api";

const { Text } = Typography;

const ImageDialog = (props) => {
  const {
    open,
    setOpen,
    file,
    setFile,
    previewImage,
    setPreviewImage,
    setIsDeleteImg,
    dealershipID,
    setSpinnerLoading,
    setFileError,
  } = props;
  const [showWebcam, setShowWebcam] = useState(false);
  const webcamRef = useRef(null);
  const fileInputRef = useRef(null);
  const [backgroundImg, setBackgroundImg] = useState(null);

  // Handle cancel ..
  const handleCancel = () => {
    setOpen(false);
    setShowWebcam(false);
    setPreviewImage(null);
  };

  // Get dealership image base on selected ID
  useEffect(() => {
    if (dealershipID) {
      const getDealershipImg = async () => {
        const response = await DealershipApi.getDealershipImage(dealershipID);
        if (response) {
          const bgImage = base64ToFile(response, "bgimage.jpg");
          setBackgroundImg(bgImage);
        }
      };
      getDealershipImg();
    }
  }, [dealershipID]);

  // Handle upload image ..
  const handleUpload = async (event) => {
    const selectedFile = event.target.files[0];
    // =====================================
    // if file get then change bg and set bg
    // =====================================
    const formData = new FormData();
    formData.append("image", selectedFile);
    formData.append("image-bg", backgroundImg);
    setSpinnerLoading(true);
    setOpen(false);
    setFileError(false);
    const response = await UserApi.removeBackground(formData);
    setSpinnerLoading(false);

    // Convert base64 to file
    const updatedFile = base64ToFile(
      response?.results[0]?.entities[0]?.image,
      response?.results[0]?.name
    );

    if (updatedFile) {
      setFile(updatedFile);
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreviewImage(e.target.result);
      };
      reader.readAsDataURL(updatedFile);
    }
    setIsDeleteImg(false);
  };

  const handelCaptureImage = () => {
    setOpen(false);
    setShowWebcam(true);
  };

  const uploadButton = (
    <>
      <input
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleUpload}
      />
      <Button
        icon={<UploadOutlined />}
        block
        style={{ marginBottom: "20px" }}
        type="dashed"
        onClick={() => fileInputRef.current.click()}
      >
        Upload Image
      </Button>
    </>
  );

  const modalFooter = [
    <Button key="cancel" onClick={handleCancel}>
      Cancel
    </Button>,
  ];

  return (
    <>
      {/* <Modal
        title={<Text>{"Image Option"}</Text>}
        open={open}
        onCancel={handleCancel}
        footer={modalFooter}
      >
        <Row justify="center" align="middle" style={{ height: "20vh" }}>
          <Col span={24} md={12} xs={24}>
            {uploadButton}
            <Button
              type="primary"
              icon={<CameraOutlined />}
              block
              onClick={handelCaptureImage}
            >
              Capture Image
            </Button>
          </Col>
        </Row>
      </Modal> */}
      {/* Web camp */}
      <WebCam
        showWebcam={open || showWebcam}
        setShowWebcam={setShowWebcam}
        webcamRef={webcamRef}
        file={file}
        setFile={setFile}
        previewImage={previewImage}
        setPreviewImage={setPreviewImage}
        setOpen={setOpen}
        setIsDeleteImg={setIsDeleteImg}
        backgroundImg={backgroundImg}
        setSpinnerLoading={setSpinnerLoading}
        setFileError={setFileError}
      />
    </>
  );
};

export default ImageDialog;
