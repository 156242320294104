import React, { useState } from "react";
import { Grid, Layout, theme } from "antd";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import LocalStorage from "../managers/LocalStorage";
import {
  SideBar,
  Navbar,
  Login,
  Dealerships,
  UserInformation,
  CreateUserInformation,
} from "../views";

const { Content } = Layout;

const Routing = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const { useBreakpoint } = Grid;
  const { xs, sm, md, lg } = useBreakpoint();
  const [open, setOpen] = useState(true);

  const isSmallScreen = xs;

  // Protected Routes ..
  const AuthenticatedRoute = ({ children }) => {
    const isAuthenticated = LocalStorage.isLoggedIn();
    const location = useLocation();
    if (!isAuthenticated) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
  };

  // if user logged in then not navigate to login and singup page ..
  const Authenticated = ({ children }) => {
    const isAuthenticated = LocalStorage.isLoggedIn();
    const location = useLocation();
    if (isAuthenticated) {
      return <Navigate to="/" state={{ from: location }} replace />;
    }
    return children;
  };

  const toggleSidebar = () => {
    setOpen(!open);
  };

  return (
    <Router>
      <Layout
        style={{
          height: "100vh",
          backgroundColor: "white",
          marginLeft: "-8px",
          marginRight: "-8px",
          marginTop: "-8px",
        }}
      >
        {/* Navbar */}
        <Navbar open={open} toggleSidebar={toggleSidebar} />
        <Layout style={{ marginTop: 64 }}>
          {/* Side bar */}
          <SideBar
            open={open}
            setOpen={setOpen}
            isSmallScreen={isSmallScreen}
          />
          {/*Main Content */}
          <Content
            style={{
              padding: open ? 5 : 10,
              marginLeft: open ? 0 : 200,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <Routes>
              {/* Login */}
              <Route
                path="/login"
                element={
                  <Authenticated>
                    <Login />
                  </Authenticated>
                }
              />
              {/* User information */}
              <Route
                path="/"
                element={
                  <AuthenticatedRoute>
                    <UserInformation />
                  </AuthenticatedRoute>
                }
              />
              {/* Create user information */}
              <Route
                path="/create"
                element={
                  <AuthenticatedRoute>
                    <CreateUserInformation />
                  </AuthenticatedRoute>
                }
              />
              {/* Update user information */}
              {/* <Route
                path="update/:id"
                element={
                  <AuthenticatedRoute>
                    <CreateUserInformation />
                  </AuthenticatedRoute>
                }
              /> */}
              {/* Dealerships  */}
              <Route
                path="/dealerships"
                element={
                  <AuthenticatedRoute>
                    <Dealerships />
                  </AuthenticatedRoute>
                }
              />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </Router>
  );
};

export default Routing;
