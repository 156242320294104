import React, { useEffect, useState } from "react";
import { Col, Form, Input, Modal, Row, message, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import { toast } from "react-toastify";

import { dealershipValidation } from "../../../../utils/validations";
import getBase64 from "../../../../utils/getBase64";
import base64ToFile from "../../../../utils/base64ToFile";
import DealershipApi from "../../../../services/apis/Dealership.Api";

const { Text } = Typography;

const FormDialog = (props) => {
  const { open, setOpen, dealershipID, setDealershipID, refetch } = props;

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [singleDealership, setSingleDealership] = useState([]);
  const [fileError, setFileError] = useState(false);
  //   Form instance
  const [form] = Form.useForm();

  // Call for get single dealership on update
  useEffect(() => {
    if (dealershipID !== null && dealershipID !== undefined) {
      const getSingleDealership = async () => {
        const response = await DealershipApi.getDealershipByID(dealershipID);
        setSingleDealership(response);
      };

      getSingleDealership();
    }
  }, [dealershipID]);

  // Set form Values on update
  useEffect(() => {
    if (dealershipID && singleDealership) {
      form.setFieldsValue({
        name: singleDealership[0]?.name,
      });

      // Set fileList and previewImage
      const base64String = singleDealership[0]?.backgroundImg?.imageURL;
      const fileName = singleDealership[0]?.backgroundImg?.name;
      // convert base64 to file object
      const getfile = base64ToFile(base64String, fileName);
      // Background image object
      const image = singleDealership[0]?.backgroundImg;
      if (image) {
        const file = {
          uid: "-1",
          name: image.name,
          status: "done",
          url: image.imageURL,
          originFileObj: getfile,
        };
        setFileList([file]);
      }
    }
  }, [singleDealership]);
  //   Show preview ..
  const handlePreview = async (file) => {
    let preview = file.preview;
    if (!file.url && !file.preview) {
      preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  // Cancel Preview ..
  const handleCancel = () => setPreviewOpen(false);
  //   Handle file input change ..
  const handleChange = ({ fileList: newFileList }) => {
    setFileError(false);
    setFileList(newFileList);
  };
  //   Upload button
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  //   Handle close dialog
  const handleClose = () => {
    setOpen(false);
    setDealershipID(null);
    setFileList([]);
    setPreviewImage("");
    setPreviewTitle("");
    form.resetFields();
    setFileError(false);
  };

  // Handle form submit
  const handleFormSubmit = async () => {
    try {
      const values = await form.validateFields();

      if (!fileList.length > 0) {
        setFileError(true);
        return;
      }
      // Create dealership
      if (!dealershipID) {
        const formData = new FormData();

        formData.append("name", values.name);
        if (fileList?.length > 0) {
          const image = fileList[0]?.originFileObj;
          formData.append("backgroundImage", image);
        }
        const response = await DealershipApi.createDealership(formData);
        if (response.success) {
          toast.success(response.message);
          form.resetFields();
          refetch();
          handleClose();
        }
      } else {
        // Update dealership ..
        const formData = new FormData();
        formData.append("name", values.name);
        if (fileList?.length > 0) {
          const image = fileList[0]?.originFileObj;
          formData.append("backgroundImage", image);
        }
        const response = await DealershipApi.updateDealership(
          dealershipID,
          formData
        );
        if (response.success) {
          toast.success(response.message);
          form.resetFields();
          refetch();
          handleClose();
        }
      }
    } catch (error) {
      console.error("Form validation error:", error);
    }
  };

  return (
    <Modal
      title={dealershipID ? "Update Dealership" : "Create Dealership"}
      centered
      open={open}
      onOk={handleFormSubmit}
      onCancel={() => handleClose()}
    >
      <Form
        form={form}
        initialValues={{ remember: true }}
        autoComplete="off"
        layout="vertical"
        onFinish={handleFormSubmit}
      >
        <Row>
          <Col span={24} md={22} xs="24">
            {/* Name input */}
            <Form.Item
              name="name"
              label="Name"
              rules={[
                { required: true, message: dealershipValidation.dealership },
              ]}
            >
              <Input placeholder="Name" size="medium" />
            </Form.Item>
            {/* Upload Button */}
            <Upload
              beforeUpload={() => false}
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
              name="backgroundImage"
            >
              {fileList.length > 0 ? null : uploadButton}
            </Upload>
          </Col>
          {/* File error */}
          {fileError && <Text type="danger">Image is required</Text>}
        </Row>
      </Form>

      {/* Preview Image Modal */}
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
    </Modal>
  );
};

export default FormDialog;
