import React from "react";
import { Modal, Typography, Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import "./styles/style.css";

const { Text } = Typography;

const ViewImageDialog = (props) => {
  const { open, setOpen, src, downloadImage } = props;

  //   Handle close dialog
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Modal
        title={<Text>Image Preview</Text>}
        open={open}
        onCancel={handleClose}
        footer={null}
        className="full-screen-modal"
      >
        <img
          src={src}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
            backgroundRepeat: "no-repeat",
          }}
        />

        <Button
          style={{ borderRadius: 6, marginBottom: 16 }}
          block
          type="primary"
          onClick={downloadImage}
          icon={<DownloadOutlined />}
        >
          Download Image
        </Button>
      </Modal>
    </>
  );
};

export default ViewImageDialog;
