export const loginValidation = {
  email: "Email is required",
  password: "Password is required",
  validEmail: "Provide a valid email",
};

export const dealershipValidation = {
  dealership: "Dealership  name is required",
};

export const userInformationValidation = {
  firstName: "Firstname is required",
  lastName: "Lastname is required",
  position: "Position is required",
};
