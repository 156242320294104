import axios from "axios";
import LocalStorage from "../../managers/LocalStorage";

const api = axios.create({
  baseURL: "https://lanyard.camclarkgroup.com/api/",
});

// Adding auth Header Globally
api.interceptors.request.use(function (config) {
  try {
    let token = LocalStorage.getToken();
    config.headers.Authorization = `bearer ${token}`;
    return config;
  } catch (error) {
    console.log("Error in setting auth header globally !", error);
  }
});

// Interceptor for handling 401 or 403 response codes
api.interceptors.response.use(
  (response) => {
    // If the response is successful, just return the response
    return response;
  },
  (error) => {
    // Checking if error response is 401 or 403
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      // Here you clear the local storage and session storage
      LocalStorage.clear(); // Assuming this method clears your local storage appropriately
      sessionStorage.clear();

      // Refresh the page
      window.location.reload();
    }

    // Return any other error without modification
    return Promise.reject(error);
  }
);

export default api;
