export const USER = "user";
export const LOGIN = `${USER}/login`;

// Dealership api constants .
export const DEALERSHIP = "dealership";
export const GET_DEALERSHIPS = `${DEALERSHIP}/dealerships`;
export const CREATE_DEALERSHIP = `${DEALERSHIP}/create`;
export const DELETE_DEALERSHIP = `${DEALERSHIP}/delete`;
export const UPDATE_DEALERSHIP = `${DEALERSHIP}/update`;
export const DEALERSHIPS_FOR_DD = `${DEALERSHIP}/dealerships/dropdown`;
export const GET_DELERSHIP_IMAGE = `${DEALERSHIP}/image`;
// User information api constants
export const CREATE_USER_INFORMATION = `${USER}/create/information`;
export const GET_USER_INFORMATION = `${USER}/information`;
export const DELETE_USER_INFORMATION = `${USER}/information/delete`;
export const UPDATE_USER_INFORMATION = `${USER}/information/update`;
export const REMOVE_BACKGROUND = `${USER}/removeBackground`;
