import React, { useState } from "react";
import { Avatar, Button, Col, Row } from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  FileImageOutlined,
} from "@ant-design/icons";
import MUIDataTable from "mui-datatables";
import { useQuery } from "react-query";

import DealershipApi from "../../services/apis/Dealership.Api";
import { FormDialog } from "./components";
import { ConfirmDialog } from "../../components";
import { muiDataTableOptions, imageData } from "../../constants/appConstants";
import "./styles/style.css";
import { toast } from "react-toastify";

const Dealerships = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [dealershipID, setDealershipID] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);

  // Get dealerships list
  const { data: dealershipList, refetch } = useQuery("DEALERSHIPS", () =>
    DealershipApi.getDealerships()
  );

  // Mui data table columns
  const columns = [
    {
      name: "ID",
      label: "ID",
    },
    {
      name: "name",
      label: "name",
      options: { filterOptions: { fullWidth: true } },
    },
    {
      name: "backgroundImg",
      label: "Background Image",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const imageURL = tableMeta.rowData[2]?.imageURL;
          return (
            <>
              {imageURL ? (
                <Avatar
                  shape="square"
                  size="large"
                  src={<img src={`${imageData}${imageURL}`} alt="avatar" />}
                />
              ) : (
                <Avatar
                  size="large"
                  shape="square"
                  icon={<FileImageOutlined />}
                  alt="BgImage"
                />
              )}
            </>
          );
        },
      },
    },
    // Actions  ...
    {
      name: "actions",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          return (
            <>
              <Row style={{ gap: "5px", marginLeft: "10px" }}>
                <Button
                  icon={<DeleteOutlined />}
                  danger
                  onClick={() => handleConfirmOpen(id)}
                ></Button>
                <Button
                  icon={<EditOutlined />}
                  type="primary"
                  ghost
                  onClick={() => handleUpdateOpen(id)}
                ></Button>
              </Row>
            </>
          );
        },
      },
    },
  ];

  // Open Create account dialog
  const handleOpneDialog = () => {
    setOpenDialog(true);
  };

  // Confirm open
  const handleConfirmOpen = (id) => {
    setDealershipID(id);
    setConfirmOpen(true);
  };

  // Update open
  const handleUpdateOpen = (id) => {
    setDealershipID(id);
    setOpenDialog(true);
  };

  // Handle delete dealership
  const handleDelete = async () => {
    const response = await DealershipApi.deleteDealership(dealershipID);
    if (response.success) {
      toast.success(response.message);
      setConfirmOpen(false);
      setDealershipID(null);
      refetch();
    } else {
      toast.error("Dealership not deleted!");
    }
  };

  const checkUsers = () => {
    let availableUsers = [
      "nwirth@camclarkgroup.com",
      "hahmed@camclarkgroup.com",
      "lvass@camclarkgroup.com",
      "ali@camclarkgroup.com",
      "bguday@camclarkgroup.com",
      "pvaughn@camclarkgroup.com",
    ];
    let user = localStorage.getItem("user");
    user = JSON.parse(user);
    console.log("user", user.email);
    if (availableUsers.includes(user.email)) {
      return true;
    }
    return false;
  };

  if (!checkUsers()) {
    return (
      <div style={{ width: "100%", height: "80vh" }}>
        <h1 style={{ textAlign: "center", marginTop: "20%" }}>
          You are not authorized to access this page
        </h1>
      </div>
    );
  }

  return (
    <>
      <Button
        className="addDealershipBtn"
        type="primary"
        onClick={handleOpneDialog}
        icon={<PlusOutlined />}
      >
        Add Dealership
      </Button>

      {/* Mui data table */}

      {dealershipList && dealershipList.length > 0 && (
        <>
          <Row className="muiTableRow">
            <Col span={24}>
              <MUIDataTable
                title={"Dealerships"}
                data={dealershipList}
                columns={columns}
                options={muiDataTableOptions}
              />
            </Col>
          </Row>
        </>
      )}
      {/* Dialog for create and update dealerships */}
      <FormDialog
        open={openDialog}
        setOpen={setOpenDialog}
        dealershipID={dealershipID}
        setDealershipID={setDealershipID}
        refetch={refetch}
      />

      {/* Delete confirm dialog */}
      <ConfirmDialog
        open={confirmOpen}
        setOpen={setConfirmOpen}
        title="Delete Dealership ?"
        description="Are you sure to delete dealership"
        onConfirm={handleDelete}
      />
    </>
  );
};

export default Dealerships;
