import React, { useState } from "react";
import { Avatar, Button, Col, Row } from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  FileImageOutlined,
} from "@ant-design/icons";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useQuery } from "react-query";

import { muiDataTableOptions, imageData } from "../../constants/appConstants";
import UserApi from "../../services/apis/User.Api";
import { ConfirmDialog } from "../../components";

const UserInfo = () => {
  const navigate = useNavigate();

  const [userInformationID, setUserInformationID] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);

  // Get dealerships list
  const { data: userInformation, refetch } = useQuery("USERS_INFORMATION", () =>
    UserApi.getUserInformation()
  );

  // Mui data table columns
  const columns = [
    {
      name: "ID",
      label: "ID",
    },
    {
      name: "firstName",
      label: "First Name",
      options: { filterOptions: { fullWidth: true } },
    },
    {
      name: "lastName",
      label: "Last Name",
      options: { filterOptions: { fullWidth: true } },
    },
    {
      name: "position",
      label: "Position",
      options: { filterOptions: { fullWidth: true } },
    },
    {
      name: "image",
      label: "Image",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const imageURL = tableMeta.rowData[4]?.imageURL;
          return (
            <>
              {imageURL ? (
                <Avatar
                  shape="square"
                  size="large"
                  src={<img src={`${imageData}${imageURL}`} alt="avatar" />}
                />
              ) : (
                <Avatar
                  size="large"
                  shape="square"
                  icon={<FileImageOutlined />}
                  alt="BgImage"
                />
              )}
            </>
          );
        },
      },
    },
    // Actions  ...
    {
      name: "actions",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          return (
            <>
              <Row style={{ gap: "5px", marginLeft: "10px" }}>
                <Button
                  icon={<DeleteOutlined />}
                  danger
                  onClick={() => handleConfirmOpen(id)}
                ></Button>
                {/* Currently hide this use for view (in future) */}
                {/* <Button
                  icon={<EditOutlined />}
                  type="primary"
                  ghost
                  onClick={() => handleUpdate(id)}
                ></Button> */}
              </Row>
            </>
          );
        },
      },
    },
  ];

  // Confirm open
  const handleConfirmOpen = (id) => {
    setUserInformationID(id);
    setConfirmOpen(true);
  };

  // Update open
  const handleUpdate = (id) => {
    navigate(`/update/${id}`);
  };

  // Handle delete dealership
  const handleDelete = async () => {
    const response = await UserApi.deleteUserInformation(userInformationID);
    if (response.success) {
      toast.success(response.message);
      setConfirmOpen(false);
      setUserInformationID(null);
      refetch();
    } else {
      toast.error("User Information not deleted!");
    }
  };
  // Hanlde create click
  const handleCreate = () => {
    navigate("/create");
  };
  return (
    <>
      <Row className="muiTableRow">
        <Col span={24} align="center">
          <Button
            // className="addDealershipBtn"
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleCreate}
          >
            Add Employee Info
          </Button>
        </Col>
      </Row>
      {/* Mui data table */}
      {/* {userInformation && userInformation.length > 0 && (
        <Row className="muiTableRow">
          <Col span={24}>
            <MUIDataTable
              title="User Information"
              data={userInformation}
              options={muiDataTableOptions}
              columns={columns}
            />
          </Col>
        </Row>
      )} */}

      {/* Delete user information */}
      <ConfirmDialog
        open={confirmOpen}
        setOpen={setConfirmOpen}
        title="Delete User Information"
        description="Are you sure to delete user information"
        onConfirm={handleDelete}
      />
    </>
  );
};

export default UserInfo;
