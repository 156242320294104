export const TOKEN = "token";
export const USER = "user";
export const imageData = "data:image/jpeg;base64,";

// Mui table options ..
export const muiDataTableOptions = {
  search: true,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  selectableRows: "none",
  responsive: "stacked",
};
