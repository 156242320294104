import React from "react";
import { Button, Layout, Typography } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import "./style.css";

const { Text } = Typography;
const { Header } = Layout;

const Navbar = (props) => {
  const { open, toggleSidebar } = props;
  const { isLoggedIn, user, signout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const isLoginPage = location.pathname === "/login";

  const handleLogout = () => {
    signout();
    navigate("/login");
    toast.success("Logout successfully");
  };
  return (
    <Header
      theme="light"
      style={{
        position: "fixed",
        width: "100%",
        zIndex: 1,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#1677ff",
        color: "white",
      }}
      className="header"
    >
      {/* Left side content */}
      <div style={{ display: "flex", alignItems: "center" }}>
        {!isLoginPage && (
          <Button
            type="primary"
            icon={open ? <RightOutlined /> : <LeftOutlined />}
            onClick={toggleSidebar}
          />
        )}
        {/* Application name */}
        <Text
          style={{
            marginLeft: 16,
            fontSize: 14,
            fontWeight: "bold",
            color: "#ffffff",
            textTransform: "uppercase",
          }}
        >
          Lanyard
        </Text>
      </div>
      {/* Right side content */}
      {isLoggedIn && (
        <div>
          <Text style={{ color: "#ffffff", marginRight: 10 }}>
            {`${user?.username}`}
          </Text>
          <Button type="default" ghost size="medium" onClick={handleLogout}>
            Logout
          </Button>
        </div>
      )}
    </Header>
  );
};

export default Navbar;
