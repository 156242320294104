const base64ToFile = (base64String, fileName) => {
  if (base64String) {
    const base64WithoutPrefix = base64String?.replace(
      /^data:[^;]+;base64,/,
      ""
    );

    const binaryArray = Uint8Array.from(atob(base64WithoutPrefix), (c) =>
      c.charCodeAt(0)
    );

    const blob = new Blob([binaryArray], { type: "image/jpg" });
    const file = new File([blob], fileName, {
      type: "image/jpg",
    });

    return file;
  }
};

export default base64ToFile;
