import {
  DEALERSHIP,
  CREATE_DEALERSHIP,
  GET_DEALERSHIPS,
  DELETE_DEALERSHIP,
  UPDATE_DEALERSHIP,
  DEALERSHIPS_FOR_DD,
  GET_DELERSHIP_IMAGE,
} from "../apiConstants";
import { api } from "../../utils";

class DealershipApi {
  static sharedIstance = new DealershipApi();

  constructor() {
    if (DealershipApi.sharedIstance != null) {
      return DealershipApi.sharedIstance;
    }
  }
  //   Get dealership lists
  async getDealerships() {
    try {
      const response = await api.get(GET_DEALERSHIPS);
      const { success, data } = response.data;
      if (success) {
        return data;
      } else {
        return null;
      }
    } catch (error) {
      return error.response.data;
    }
  }
  //   Create dealership
  async createDealership(body) {
    try {
      const response = await api.post(CREATE_DEALERSHIP, body);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
  //   Get dealership by ID
  async getDealershipByID(id) {
    try {
      const response = await api.get(`${DEALERSHIP}/${id}`);
      const { success, data } = response.data;
      if (success) {
        return data;
      } else {
        return null;
      }
    } catch (error) {
      return error.response.data;
    }
  }
  //   Update dealership
  async updateDealership(id, body) {
    try {
      const response = await api.put(`${UPDATE_DEALERSHIP}/${id}`, body);
      return response.data;
    } catch (error) {
      console.log("update api call error :", error);
      return error.response.data;
    }
  }
  // Get dealerships for drop down .
  async dealershipsForDD() {
    try {
      const response = await api.get(DEALERSHIPS_FOR_DD);
      const { success, data } = response.data;
      if (success) {
        return data;
      } else {
        return null;
      }
    } catch (error) {
      return error?.response?.data;
    }
  }
  //   Delete dealership
  async deleteDealership(id) {
    try {
      const response = await api.delete(`${DELETE_DEALERSHIP}/${id}`);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
  // Get dealership image
  async getDealershipImage(id) {
    try {
      const response = await api.get(`${GET_DELERSHIP_IMAGE}/${id}`);
      const { success, data } = response.data;
      if (success) {
        return data;
      } else {
        return null;
      }
    } catch (error) {
      return error?.response?.data;
    }
  }
}

export default DealershipApi.sharedIstance;
