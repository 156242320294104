import React, { useState, useEffect } from "react";
import { readPsd } from "ag-psd";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import axios from "../../../../utils/axios";

const PsdPreviewComponent = ({
  psdFile,
  name,
  position,
  locationTop,
  locationBottom,
  image,
  setLanyardImage,
}) => {
  const [previewImage, setPreviewImage] = useState(null);
  const [base64Image, setBase64Image] = useState(null);

  function renderCustomLayer(layer, updatedText, ctx, scaleFactor) {
    const fontSize = layer.text.style.fontSize * scaleFactor;
    const fontFamily = layer.text.style.font?.name || "Palatino"; // Fallback to Palatino if no font is specified

    ctx.font = `600 ${fontSize}px "${fontFamily}"`;
    ctx.fillStyle = layer.text.style.fillColor
      ? `rgb(${layer.text.style.fillColor.r}, ${layer.text.style.fillColor.g}, ${layer.text.style.fillColor.b})`
      : "black";
    ctx.textAlign =
      layer.text.paragraphStyle?.justification === "center"
        ? "center"
        : "start";

    const centerX = (layer.left + (layer.right - layer.left) / 2) * scaleFactor;
    const centerY = (layer.top + fontSize) * scaleFactor; // Adjust for baseline or specific alignment

    ctx.fillText(updatedText, centerX, centerY);
  }

  function renderUpdatedPsdToCanvas(
    psd,
    canvas,
    ctx,
    name,
    position,
    locationTop,
    locationBottom,
    image,
    scaleFactor
  ) {
    psd.children.forEach((layer) => {
      if (layer.name === "Pic Frame" && image) {
        const img = new Image();
        img.onload = function () {
          // Adjust the y-coordinate and size as needed
        };
        img.onerror = () => console.error("Error loading layer image");
        img.src = image; // Assume this is a valid image URL or base64 data
        const imgWidth = 350 * scaleFactor;
        const centerX =
          layer.mask.left +
          (layer.mask.right - layer.mask.left) / 2 -
          imgWidth / 2;
        ctx.drawImage(
          img,
          centerX * scaleFactor,
          layer.mask.top * scaleFactor,
          imgWidth,
          imgWidth
        );
      } else if (layer.name === "Name" && name) {
        renderCustomLayer(layer, name, ctx, scaleFactor);
      } else if (layer.name === "Title " && position) {
        renderCustomLayer(layer, position, ctx, scaleFactor);
      }

      // else if (layer.name === "Location" && locationTop) {
      //   renderCustomLayer(layer, locationTop, ctx, scaleFactor);
      //   renderCustomLayer(
      //     { ...layer, top: layer.top + 50 },
      //     locationBottom,
      //     ctx,
      //     scaleFactor
      //   );
      // }
      else if (layer.canvas) {
        ctx.drawImage(
          layer.canvas,
          layer.left * scaleFactor,
          layer.top * scaleFactor,
          layer.canvas.width * scaleFactor,
          layer.canvas.height * scaleFactor
        );
      }
    });
  }

  function downloadImageAt300DPI() {
    // Create an anchor element to trigger the download
    const a = document.createElement("a");
    const filename = name + "_300dpi.jpg";
    a.href = base64Image; // Use the base64 image data directly
    a.download = filename; // Set the desired filename
    document.body.appendChild(a); // Append the anchor to the body
    a.click(); // Trigger the download
    document.body.removeChild(a); // Clean up by removing the anchor
  }

  // function downloadImageAt300DPI() {
  //   // Desired dimensions in pixels
  //   const desiredWidthPixels = 642;
  //   const desiredHeightPixels = 1017;

  //   // DPI
  //   const dpi = 300;

  //   // Convert desired dimensions to inches for 300 DPI
  //   const targetPrintWidthInInches = desiredWidthPixels / dpi;
  //   const targetPrintHeightInInches = desiredHeightPixels / dpi;

  //   // Convert inches to pixels at 300 DPI
  //   const targetWidth = targetPrintWidthInInches * dpi; // should be 642 pixels
  //   const targetHeight = targetPrintHeightInInches * dpi; // should be 1017 pixels

  //   // ID of the image you want to download
  //   const imageSrc = document.getElementById("hidden_image").src;

  //   // Filename for the downloaded image
  //   const filename = name + "_300dpi.jpg";

  //   // Create an Image object
  //   const img = new Image();
  //   img.crossOrigin = "Anonymous"; // This might be necessary for web-hosted images
  //   img.src = imageSrc;

  //   img.onload = () => {
  //     // Create a canvas element
  //     const canvas = document.createElement("canvas");
  //     canvas.width = targetWidth;
  //     canvas.height = targetHeight;

  //     // Draw the image on the canvas with the target dimensions
  //     const ctx = canvas.getContext("2d");
  //     ctx.drawImage(img, 0, 0, targetWidth, targetHeight);

  //     // Convert the canvas to a Blob, then trigger a download
  //     canvas.toBlob((blob) => {
  //       const newImgUrl = URL.createObjectURL(blob);
  //       const a = document.createElement("a");
  //       a.href = newImgUrl;
  //       a.download = filename;
  //       a.click();
  //       URL.revokeObjectURL(newImgUrl); // Clean up after download
  //     }, "image/jpeg");
  //   };
  // }

  const processImage = async () => {
    const imageElement = document.getElementById("hidden_image");
    const canvas = document.createElement("canvas");
    canvas.width = imageElement.naturalWidth;
    canvas.height = imageElement.naturalHeight;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(imageElement, 0, 0);

    // Convert the canvas to a base64 string
    const base64Image = canvas.toDataURL("image/jpeg");

    try {
      const response = await axios.post("images/adjust-dpi", {
        imageSrc: base64Image,
      });
      console.log("process image :", response.data);
      const updatedImageBase64 = response.data.imageBase64;
      return updatedImageBase64;
    } catch (error) {
      console.error("Error processing image:", error);
    }
  };

  useEffect(() => {
    if (!psdFile) return;

    const reader = new FileReader();

    reader.onload = async (e) => {
      const buffer = e.target.result;
      const psd = readPsd(buffer);

      const displayCanvas = document.createElement("canvas");
      const displayCtx = displayCanvas.getContext("2d");
      displayCanvas.width = psd.width;
      displayCanvas.height = psd.height;

      // Render display version
      renderUpdatedPsdToCanvas(
        psd,
        displayCanvas,
        displayCtx,
        name,
        position,
        locationTop,
        locationBottom,
        image,
        1
      );

      // Use the display canvas for preview
      displayCanvas.toBlob(
        (blob) => {
          const imageUrl = URL.createObjectURL(blob);
          setPreviewImage(imageUrl);
          setTimeout(async () => {
            let base64Image = await processImage();
            setBase64Image(base64Image);
            setLanyardImage(base64Image);
          }, 1000);
        },
        "image/jpeg",
        0.99
      );
    };

    reader.readAsArrayBuffer(psdFile);
  }, [psdFile, name, image, position, locationTop, locationBottom]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "20px",
      }}
    >
      {previewImage && (
        <img
          style={{
            border: "1px solid",
            borderRadius: "6px",
            width: "100%",
            height: 600,
            maxWidth: 642,
            objectFit: "contain",
          }}
          src={previewImage}
          alt="Modified PSD Preview"
        />
      )}
      {previewImage && (
        <img
          id="hidden_image"
          style={{
            width: "100%",
            height: 1017,
            maxWidth: 642,
            objectFit: "contain",
            display: "none",
          }}
          src={previewImage}
          alt="Modified PSD Preview"
        />
      )}
      <Button
        style={{ borderRadius: 6, marginBottom: 16 }}
        block
        type="link"
        // onClick={handleDownload}
        onClick={downloadImageAt300DPI}
        icon={<DownloadOutlined />}
      >
        Download Lanyard Image
      </Button>
    </div>
  );
};

export default PsdPreviewComponent;
