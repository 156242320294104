import React, { useState, useEffect } from "react";
import Webcam from "react-webcam";
import base64ToFile from "../../../../utils/base64ToFile";
import { Button, Col, Modal, Row, Typography } from "antd";
// import frame from "../../../../assets/360_F_140461899_dvRngd7xvZtqCUHLiIyRjgflq2EmwnVP (1).png";
import UserApi from "../../../../services/apis/User.Api";
import { imageData } from "../../../../constants/appConstants";

const { Text } = Typography;

const WebCam = (props) => {
  const {
    showWebcam,
    setShowWebcam,
    webcamRef,
    setFile,
    setPreviewImage,
    setIsDeleteImg,
    backgroundImg,
    setSpinnerLoading,
    setOpen,
    setFileError,
  } = props;
  const captureImage = async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    const capturedFile = base64ToFile(imageSrc, "User-image.jpg");
    // remove backgroudn  ..
    const formData = new FormData();
    formData.append("image", capturedFile);
    formData.append("image-bg", backgroundImg);
    setShowWebcam(false);
    setOpen(false);
    setFileError(false);
    setSpinnerLoading(true);

    const response = await UserApi.removeBackground(formData);

    setSpinnerLoading(false);
    // Convert base64 to file
    const updatedFile = base64ToFile(
      response?.results[0]?.entities[0]?.image,
      response?.results[0]?.name
    );

    if (updatedFile) {
      setIsDeleteImg(false);
      setFile(updatedFile);
      setPreviewImage(
        `${imageData}${response?.results[0]?.entities[0]?.image}`
      );
      setShowWebcam(false);
    }
  };

  const handleCancel = () => {
    //close image option dialog
    setOpen(false);

    setShowWebcam(false);
    setPreviewImage(null);
    setFile(null);
  };

  const [devices, setDevices] = useState([]);
  const [facingMode, setFacingMode] = useState("envirnoment");
  const [currentDeviceId, setCurrentDeviceId] = useState();

  const handleDevices = React.useCallback(
    (mediaDevices) =>
      setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
    [setDevices]
  );
  const printDevices = React.useCallback((mediaDevices) => {
    console.log("mediaDevices", mediaDevices);
    console.log(
      "filtered devices:",
      mediaDevices.filter(({ kind }) => kind === "videoinput")
    );
  }, []);

  useEffect(() => {
    console.log("navigator", navigator.mediaDevices.enumerateDevices());
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  const switchCamera = () => {
    const videoDevices = devices.filter(
      (device) => device.kind === "videoinput"
    );
    console.log("videoDevices length:", videoDevices.length);
    if (videoDevices.length > 1) {
      const currentDeviceIndex = videoDevices.findIndex(
        (device) => device.deviceId === currentDeviceId
      );
      const nextDeviceIndex = (currentDeviceIndex + 1) % videoDevices.length;
      setCurrentDeviceId(videoDevices[nextDeviceIndex].deviceId);
    }
    // if (facingMode === "user") setFacingMode("envirnoment");
    // else setFacingMode("user");
  };

  const webcamButtons = (
    <Row justify="center" style={{ marginTop: "10px", gap: "10px" }}>
      <Col>
        <Button type="text" onClick={handleCancel}>
          Cancel
        </Button>
      </Col>
      {devices.length > 1 && (
        <Col>
          <Button onClick={switchCamera}>Switch Camera</Button>
        </Col>
      )}
      <Col>
        <Button type="primary" onClick={captureImage}>
          Capture
        </Button>
      </Col>
    </Row>
  );

  useEffect(() => {
    return () => {
      if (webcamRef.current && webcamRef.current.stream) {
        webcamRef.current.stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  return (
    <>
      <Modal
        title={<Text>{"Capture Image"}</Text>}
        open={showWebcam}
        onCancel={handleCancel}
        footer={webcamButtons}
      >
        <Row justify="center" align="middle">
          <Col span={24}>
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "400px",
              }}
            >
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotQuality={1}
                screenshotFormat="image/jpg"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                videoConstraints={{
                  deviceId: currentDeviceId,
                  facingMode: facingMode,
                }}
              />
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default WebCam;
