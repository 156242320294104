import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Space,
  Select,
  Typography,
  Checkbox,
} from "antd";
import { ImageDialog, ViewImageDialog } from "..";
import {
  FileImageOutlined,
  PlusOutlined,
  SaveOutlined,
  DownloadOutlined,
  DeleteFilled,
  EyeFilled,
} from "@ant-design/icons";
// import badgePsd from "../../../../assets/Badge.psd";
import { PsdPreview } from "./../../components";

import { useNavigate, useParams } from "react-router-dom";

import { userInformationValidation } from "../../../../utils/validations";
import UserApi from "../../../../services/apis/User.Api";
import { toast } from "react-toastify";
import DealershipApi from "../../../../services/apis/Dealership.Api";
import base64ToFile from "../../../../utils/base64ToFile";
import { Spinner } from "../../../../components";

import "./styles/style.css";

const { Text } = Typography;

const UserForm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [dealershipID, setDealershipID] = useState(null);
  const [dealershipsForDD, setDealershipsForDD] = useState([]);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [file, setFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [lanyardImage, setLanyardImage] = useState(null);
  const [openPreviewImage, setOpenPreviewImage] = useState(false);
  const [singleUserInformation, setSingleUserInformation] = useState(null);
  const [selectValue, setSelectValue] = useState(null);
  const [isDeleteImg, setIsDeleteImg] = useState(false);
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [counter, setCounter] = useState(0);
  const [checkedOptions, setCheckedOptions] = useState({
    printLanyard: true,
    addToWebsite: true,
  });
  // Form instance
  const [form] = Form.useForm();
  // On finish
  const handleSubmit = async () => {
    const values = await form.validateFields();
    const formValues = { ...values, dealershipID: dealershipID };

    if (!file) {
      setFileError(true);
      return;
    }
    // form data
    const formData = new FormData();

    formData.append("firstName", formValues.firstName);
    formData.append("lastName", formValues.lastName);
    formData.append("position", formValues.position);
    formData.append("dealershipID", formValues.dealershipID);
    formData.append("dealershipName", selectValue);
    if (lanyardImage && psdBlob) formData.append("lanyardImage", lanyardImage);

    if (file) {
      formData.append("userImage", file);
    }

    formData.append("printLanyard", checkedOptions.printLanyard);
    formData.append("addToWebsite", checkedOptions.addToWebsite);

    console.log("formData", formData);
    console.log("checkedOptions", checkedOptions);
    // Update user information
    if (params?.id) {
      formData.append("isDeleteImg", isDeleteImg);
      const response = await UserApi.updateUserInformatin(params?.id, formData);
      if (response.success) {
        toast.success(response.message);
        form.resetFields();
        setPreviewImage(null);
        setLanyardImage(null);
        // navigate("/");
      }
    } else {
      const response = await UserApi.createUserInformation(formData);
      if (response.success) {
        toast.success(response.message);
        form.resetFields();
        setFile(null);
        setPreviewImage(null);
        setLanyardImage(null);
        // navigate("/");
      }
    }
  };
  // Get single user information  ..
  useEffect(() => {
    if (params?.id) {
      const getSingleUserInfo = async () => {
        const response = await UserApi.getUserInformationById(params?.id);
        setSingleUserInformation(response);
      };
      getSingleUserInfo();
    }
  }, [params?.id]);

  // set Form on update
  useEffect(() => {
    if (params?.id && singleUserInformation) {
      form.setFieldsValue({
        firstName: singleUserInformation[0]?.firstName,
        lastName: singleUserInformation[0]?.lastName,
        position: singleUserInformation[0]?.position,
      });
      setDealershipID(singleUserInformation[0]?.dealershipID);
      setSelectValue(singleUserInformation[0]?.dealershipName);
      // Set file and preview
      const base64String = singleUserInformation[0]?.image?.imageUrl;
      const fileName = singleUserInformation[0]?.image?.name;
      const file = base64ToFile(base64String, fileName);
      // User image object
      setFile(file);
      setPreviewImage(base64String);
    }
  }, [singleUserInformation]);
  // Hnalde select image dialog
  const handleSelectImage = () => {
    setOpenImageDialog(true);
  };
  //Handle delete Image
  const handleDeleteImage = () => {
    setFile(null);
    setPreviewImage(null);

    // if user want to set image empty on update
    setIsDeleteImg(true);
  };
  // Handle open preview image ..
  const handlePreviewImage = () => {
    setOpenPreviewImage(true);
  };
  // Get dealership list
  useEffect(() => {
    const getDealershipsDD = async () => {
      const response = await DealershipApi.dealershipsForDD();
      if (response) {
        setDealershipsForDD(response);
      }
    };
    getDealershipsDD();
  }, []);
  // Handle dealership change
  const handleDealershipChange = (value, data) => {
    setDealershipID(value);
    setSelectValue(data?.label);
  };

  const [psdBlob, setPsdBlob] = useState(null);

  useEffect(() => {
    if (!selectValue) return;
    // Original URL
    const baseUrl = "https://lanyard.camclarkgroup.com/dealership-images";
    const dealership = selectValue; // Ensure this is properly URL-encoded
    const fileName = "Badge.psd";

    // Encode URI components
    const encodedDealership = encodeURIComponent(dealership);
    const encodedFileName = encodeURIComponent(fileName);

    // Construct the full URL
    const fullUrl = `${baseUrl}/${encodedDealership}/${encodedFileName}`;

    // Fetch the PSD file
    fetch(fullUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        console.log("response", response);
        return response.blob(); // Convert the response to a Blob
      })
      .then((blob) => {
        console.log("psd blob", blob);
        if (blob?.size > 10000) setPsdBlob(blob); // Assuming setPsdBlob is a useState setter or similar function

        // Additional step: Use blob with psdReader here, if needed
        // Example: psdReader(blob).then(psd => { /* Use the PSD data */ });
      })
      .catch((error) => {
        console.error("Error fetching PSD file:", error);
      });
  }, [selectValue]); // Added selectValue as a dependency to re-run effect when it changes

  const downloadImage = () => {
    //main_image is the id of the image tag
    const image = document.getElementById("main_image").src;
    const a = document.createElement("a");
    a.href = image;
    a.download = `${
      form.getFieldValue("firstName") +
      " " +
      form.getFieldValue("lastName") +
      ".jpg"
    }`;
    a.click();
  };

  return (
    <Row justify="center" align="top" className="">
      {/* Loader */}
      <Spinner loading={spinnerLoading} />
      {/* Form */}
      <Col span={24} xs={24} md={12}>
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          autoComplete="off"
          onChange={() => setCounter(counter + 1)}
        >
          <Form.Item
            label="Dealership"
            className="user-info-form"
            help={
              dealershipID && file ? "Remove image to change dealership" : null
            }
          >
            <Select
              placeholder="Dealership"
              onChange={handleDealershipChange}
              options={dealershipsForDD.map((data) => ({
                label: data?.name,
                value: data?.ID,
              }))}
              value={selectValue}
            />
          </Form.Item>
          {dealershipID && (
            <>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                  {
                    required: true,
                    message: userInformationValidation.firstName,
                  },
                ]}
              >
                <Input placeholder="First Name" />
              </Form.Item>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                  {
                    required: true,
                    message: userInformationValidation.lastName,
                  },
                ]}
              >
                <Input placeholder="Last Name" />
              </Form.Item>
              <Form.Item
                name="position"
                label="Position"
                rules={[
                  {
                    required: true,
                    message: userInformationValidation.position,
                  },
                ]}
              >
                <Input placeholder="Position" />
              </Form.Item>
              {!previewImage && (
                <>
                  <Text type="secondary">
                    For best results, have a white/plain background.
                  </Text>
                  <Form.Item style={{ marginTop: 8 }}>
                    <Button
                      icon={<FileImageOutlined />}
                      type="primary"
                      ghost
                      onClick={handleSelectImage}
                    >
                      *Select Image
                    </Button>
                  </Form.Item>
                  {/* File Error  */}
                  {fileError && (
                    <div className="file-error-div">
                      <Text type="danger">User image required</Text>
                    </div>
                  )}
                </>
              )}
              {/* Preview Image */}
              {previewImage && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: 32,
                  }}
                  className="preivew-img-div "
                >
                  <>
                    <img
                      src={previewImage}
                      id="main_image"
                      style={{ display: "none", width: "100%", height: "100%" }}
                    />
                    <img
                      className="user-preview-img"
                      src={previewImage}
                      alt="Selected or Captured"
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyItems: "space-around",
                        marginTop: -32,
                      }}
                    >
                      <Space
                        style={{
                          cursor: "pointer",
                          marginRight: 24,
                        }}
                      >
                        <DeleteFilled
                          className="delete-icon"
                          style={{
                            boxShadow: "0px 4px 20px 1px red",
                            borderRadius: "50%",
                          }}
                          onClick={handleDeleteImage}
                        />
                      </Space>
                      <Space
                        style={{
                          cursor: "pointer",
                          marginRight: 24,
                        }}
                      >
                        <EyeFilled
                          className="view-icon"
                          onClick={handlePreviewImage}
                        />
                      </Space>
                      <Space
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <DownloadOutlined
                          className="view-icon"
                          onClick={downloadImage}
                        />
                      </Space>
                    </div>
                  </>
                </div>
              )}

              {previewImage && psdBlob && (
                <div key={counter} style={{ marginBottom: 16 }}>
                  <PsdPreview
                    psdFile={psdBlob}
                    name={
                      form.getFieldValue("firstName") +
                      " " +
                      form.getFieldValue("lastName")
                    }
                    position={form.getFieldValue("position")}
                    locationTop={`Cam Clark Group`}
                    locationBottom={`Great Falls, Montana`}
                    image={previewImage}
                    setLanyardImage={setLanyardImage}
                  />
                </div>
              )}

              <Form.Item style={{ textAlign: "center" }}>
                <Checkbox
                  checked={checkedOptions.printLanyard}
                  onChange={(e) =>
                    setCheckedOptions((prev) => ({
                      ...prev,
                      printLanyard: e.target.checked,
                    }))
                  }
                >
                  Print Lanyard
                </Checkbox>
                <Checkbox
                  checked={checkedOptions.addToWebsite}
                  onChange={(e) =>
                    setCheckedOptions((prev) => ({
                      ...prev,
                      addToWebsite: e.target.checked,
                    }))
                  }
                >
                  Add to Website
                </Checkbox>
              </Form.Item>

              {previewImage && (
                <Button
                  style={{ borderRadius: 6, marginBottom: 16 }}
                  block
                  type="primary"
                  htmlType="submit"
                >
                  SUBMIT TO IT
                </Button>
              )}
            </>
          )}
        </Form>
      </Col>
      {/* Image options dialog */}
      <ImageDialog
        open={openImageDialog}
        setOpen={setOpenImageDialog}
        file={file}
        setFile={setFile}
        previewImage={previewImage}
        setPreviewImage={setPreviewImage}
        setIsDeleteImg={setIsDeleteImg}
        dealershipID={dealershipID}
        setSpinnerLoading={setSpinnerLoading}
        setFileError={setFileError}
      />

      {/* View image dialog */}
      <ViewImageDialog
        src={previewImage}
        open={openPreviewImage}
        setOpen={setOpenPreviewImage}
        downloadImage={downloadImage}
      />
    </Row>
  );
};

export default UserForm;
