import React, { useState } from "react";
import {
  QuestionCircleOutlined,
  LoadingOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Button, Modal, Popconfirm, Typography } from "antd";

const { Text } = Typography;

const ConfirmDialog = (props) => {
  const { open, setOpen, title, description, onConfirm } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showPopconfirm = () => {
    setOpen(true);
  };

  //   Handle Ok .
  const handleOk = () => {
    setConfirmLoading(true);

    setTimeout(() => {
      setOpen(false);
      onConfirm();
      setConfirmLoading(false);
    }, 2000);
  };

  //   Handle Cnacel .
  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  //   Moodal footer..
  const modalFooter = [
    <Button key="cancel" onClick={handleCancel}>
      Cancel
    </Button>,
    <Button
      key="delete"
      type="primary"
      danger
      icon={confirmLoading ? <LoadingOutlined /> : <DeleteOutlined />}
      onClick={handleOk}
      disabled={confirmLoading}
    >
      Delete
    </Button>,
  ];

  return (
    <Modal
      title={<Text>{title}</Text>}
      open={open}
      onCancel={handleCancel}
      footer={modalFooter}
    >
      <Text>{description}</Text>
    </Modal>
  );
};

export default ConfirmDialog;
